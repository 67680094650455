import {domReady} from '@roots/sage/client';
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm'



/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }


  let sliders = document.getElementsByClassName("glide");
  if (sliders != undefined && sliders != "" && sliders.length != 0) {
    new Glide(".glide", {
      gap: 0,
      autoplay: 3000,
      hoverpause: false,
      class: {
        nav: {
          active: "custom-active-class",
        },
      },
    }).mount({ Controls, Breakpoints, Swipe });
  }

  let slidersNews = document.getElementsByClassName("glide-news");
  if (slidersNews != undefined && slidersNews != "" && slidersNews.length != 0) {
    new Glide(".glide-news", {
      gap: 120,
      rewind: false,
      autoplay: 3000,
      hoverpause: false,
      perView: 3,
      breakpoints: {
        1024: {
          perView: 2,
          gap: 40,
        },
        480: {
          perView: 1,
          gap: 30,
          peek: 0,
        },
      },
      // class: {
      //   nav: {
      //     active: "custom-active-class",
      //   },
      // },
    }).mount({ Controls, Breakpoints, Swipe });
  }
  let slidersNewsExternal = document.getElementsByClassName("glide-news-ext");
  if (slidersNewsExternal != undefined && slidersNewsExternal != "" && slidersNewsExternal.length != 0) {
    new Glide(".glide-news-ext", {
      gap: 32,
      rewind: false,
      autoplay: 3000,
      hoverpause: false,
      perView: 3,
      breakpoints: {
        1024: {
          perView: 2,
          gap: 16,
        },
        480: {
          perView: 1,
          gap: 8,
          peek: 0,
        },
      },
      // class: {
      //   nav: {
      //     active: "custom-active-class",
      //   },
      // },
    }).mount({ Controls, Breakpoints, Swipe });
  }

  const slidersEvents = document.querySelectorAll(".glide-events");
  const confSliderEvents = {
    gap: 32,
    rewind: false,
    autoplay: 3000,
    hoverpause: false,
    perView: 3,
    breakpoints: {
      1024: {
        perView: 2,
        gap: 40,
      },
      480: {
        perView: 1,
        gap: 30,
        peek: 0,
      },
    },
    // class: {
    //   nav: {
    //     active: "custom-active-class",
    //   },
    // },
  };
  slidersEvents.forEach((item) => {
    new Glide(item, confSliderEvents).mount({
      Controls,
      Breakpoints,
      Swipe,
    });
  });

  const slidersFiles = document.querySelectorAll(".glide-files");
  const confSlidersFiles = {
    gap: 32,
    rewind: false,
    autoplay: 3000,
    hoverpause: false,
    perView: 2,
    breakpoints: {
      1024: {
        perView: 2,
        gap: 40,
      },
      790: {
        perView: 1,
        gap: 20,
        peek: 0,
      },
    },
    // class: {
    //   nav: {
    //     active: "custom-active-class",
    //   },
    // },
  };
  slidersFiles.forEach((item) => {
    new Glide(item, confSlidersFiles).mount({
      Controls,
      Breakpoints,
      Swipe,
    });
  });

  let sliderTestimonies = document.getElementsByClassName("glide-testimonies");
  if (sliderTestimonies != undefined && sliderTestimonies != "" && sliderTestimonies.length != 0) {
    new Glide(".glide-testimonies", {
      // gap: 120,
      rewind: true,
      autoplay: 3000,
      hoverpause: false,
      perView: 1,
      // class: {
      //   nav: {
      //     active: "custom-active-class",
      //   },
      // },
    }).mount({ Controls, Breakpoints, Swipe });
  }
  // application code
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
Alpine.plugin(focus);
document.addEventListener('alpine:init', () => {
  Alpine.data("itemFilters", () => ({
    btnText: '',
    buttonDisabled: false,
    selectYear: null,
    selectMonth: null,
    results: document.getElementById("filter-results"),

    init() {
      // this.btnText = document.getElementById("btn_filter").getAttribute('data-text');
      if (this.results == undefined || this.results == "" || this.results.length == 0) {
        this.results = document.getElementById("results");
      }
      this.setParametersFromUrl();
    },
    setParametersFromUrl() {
      // select from url parameters
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search.slice(1));
      // parse each params
      params.forEach((element, key) => {
        // checch if select exist with key id
        if (document.getElementById(key) != undefined) {
          // set value
          document.getElementById(key).value = element;
        }
      });
      this.search();
    },

    reset() {
      document.querySelectorAll("select").forEach(function (item) {
        item.selectedIndex = 0;
      });
      this.search();
    },
    search() {
      // this.btnText = "Loading...";
      const searchForm = document.getElementById("filter_form");
      let formData = new FormData(searchForm);
      this.results.classList.add("opacity-10");

      // set parameters in url for sharing link
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search.slice(1));
      formData.forEach((element, key) => {
        if (element !== "" && element !== null && element !== undefined && key !== 'post') {
          params.set(key, element);
        } else {
          params.delete(key);
        }
      });
      url.search = params.toString();
      window.history.replaceState({}, "", url);

      formData.append("action", "filter");
      // paged
      formData.append("paged", searchForm.getAttribute("data-paged"));
      // formData.append("post", "post");
      formData.append("nonce", wp.nonce);

      fetch(wp.ajaxurl, { method: "POST", body: formData })
        .then((response) => {
          response.json().then((data) => {
            results.innerHTML = data.html;
            this.productCount = data.count;
          });
        })
        .catch(() => {
          // this.btnText = "Ooops! Something went wrong!";
        })
        .finally(() => {
          results.classList.remove("opacity-10");
        });
    },
  }));

  Alpine.data("itemFiltersExtranet", () => ({
    // btnText: "",
    buttonDisabled: false,
    // selectYear: null,
    // selectMonth: null,
    loading: false,
    results: document.getElementById("filter-results"),
    pagination: document.getElementById("filter-pagination"),
    form: document.getElementById("filters"),

    init() {
      // console.log("filterExtranet");
      if (
        this.results == undefined ||
        this.results == "" ||
        this.results.length == 0
      ) {
        this.results = document.getElementById("results");
      }
      this.pageClickEvent();

      this.setParametersFromUrl();
    },
    setParametersFromUrl() {
      // select from url parameters
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search.slice(1));
      // parse each params
      params.forEach((element, key) => {
        // checch if select exist with key id
        if (document.getElementById(key) != undefined) {
          // set value
          document.getElementById(key).value = element;
        }
      });
      this.changeSelect();
    },

    // pageChange(e) {
    //   console.log(e.target);
    // },

    pageClickEvent() {
      document
        .querySelectorAll("a.pagechange, button.pagechange")
        .forEach((page) => {
          page.removeAttribute("href");
          page.addEventListener(
            "click",
            function (e) {
              e.preventDefault();
              const nextPage = e.target.getAttribute("data-targetpage");
              this.form.setAttribute("data-paged", nextPage);
              this.changeSelect();
            }.bind(this)
          );
        });
    },

    setUrlParameters() {
      let formData = new FormData(this.form);
      // set parameters in url for sharing link
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search.slice(1));
      formData.forEach((element, key) => {
        if (
          element !== "" &&
          element !== null &&
          element !== undefined &&
          key !== "post"
        ) {
          params.set(key, element);
        } else {
          params.delete(key);
        }
      });
      url.search = params.toString();
      window.history.replaceState({}, "", url);
    },

    reset() {
      document.querySelectorAll("select").forEach(function (item) {
        item.selectedIndex = 0;
      });
      this.changeSelect();
    },

    changeSelect() {
      // console.log("change !");
      this.loading = true;
      this.setUrlParameters();

      this.btnText = "Loading...";
      let formData = new FormData(this.form);
      this.results.classList.add("opacity-10");

      formData.append("action", "filterextranet");
      formData.append("nonce", wp.nonce);
      formData.append("post_type", this.form.getAttribute("data-post"));
      formData.append("nb_col", this.form.getAttribute("data-col"));
      formData.append("paged", this.form.getAttribute("data-paged"));
      formData.append("ppp", this.form.getAttribute("data-ppp"));
      // formData.append("taxonomy", );

      fetch(wp.ajaxurl, { method: "POST", body: formData })
        .then((response) => {
          response.json().then((data) => {
            this.results.innerHTML = data.html;
            this.pagination.innerHTML = data.pagination;
            this.pageClickEvent();
            // this.btnText = "validate";
            this.productCount = data.count;
          });
        })
        .catch(() => {
          // this.btnText = "Ooops! Something went wrong!";
        })
        .finally(() => {
          this.results.classList.remove("opacity-10");
        });
    },
  }));
});

window.Alpine = Alpine;
Alpine.start();

import.meta.webpackHot?.accept(main);
